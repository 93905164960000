import * as React from "react";
import { Link } from "gatsby";
import { Layout } from "../components";

// markup
const NotFoundPage = () => (
  <Layout>
    <div className="flex flex-col items-center max-w-7xl mx-auto px-4 sm:px-8 mt-20 mb-32 space-y-8">
      <h1 className="uppercase text-5xl">Page Not Found</h1>
      <h2>We can’t seem to find the page you’re looking for.</h2>
      <p>404. Page Not Found</p>
      <Link
        className="inline-flex items-center px-4 py-2 border border-transparent text-xl font-medium shadow-sm text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-denim-blue"
        to="/"
      >
        Back to Home
      </Link>
    </div>
  </Layout>
);

export default NotFoundPage;
